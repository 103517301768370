import React from "react"
import ShopifyLogo from "-!svg-react-loader?props[]=className:shopify!../images/svg/shopify.svg";
import ExternalLink from "-!svg-react-loader?props[]=className:shopify!../images/svg/external-link.svg";
import sorelleHome from "../images/portfolio/sorelle-gallery-home.jpg";

import Layout from "../components/layout"
import SEO from "../components/seo"

const SorelleGallery = () => (
  <Layout>
    <SEO title="Project | Sorelle Gallery" />
    <div className="project">
      <div className="heading">
        <div class="title">
          <h1>Sorelle Gallery</h1>
          <div class="icons">
            <ShopifyLogo alt="Shopify" />
          </div>
        </div>
        <div class="site-link">
          <a href="https://sorellegallery.com/" target="_blank">Website <ExternalLink /></a>
        </div>
      </div>

      <div class="description">
        <p>Sorelle Gallery Fine Art specializes in a wide variety of contemporary, modern and traditional art from over 40 established artists. With access to Sorelle’s consultation and advisory service, art-seekers looking for a personal, professional touch have their wish lists fulfilled; from first meeting to art installation.</p>
      </div>

      <div class="collaborations">
        <p>
          <span>In collaboration with</span>
          <div class="links">
            <a href="https://anthemcreative.co/" target="_blank">Anthem Creative</a>
            <a href="https://aeolidia.com/" target="_blank">Aeolidia</a>
          </div>
       </p>
      </div>

      <div class="project-body">
        <img src={sorelleHome} />
     </div>
    </div>
  </Layout>
)

export default SorelleGallery